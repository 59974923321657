import React from 'react'

import { FaTwitter } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';



export default function Footer() {

    const copyrightYear = new Date()

    return(
        <div class="footer-wrapper">
            <h4 class="footer-text">
            © Copyright 2014 - {copyrightYear.getFullYear()} &nbsp; &nbsp; <span class="subscribe-footer-text"><a href="https://mailchi.mp/e9d90a699a2d/foundstages">SUBSCRIBE TO OUR NEWSLETTER</a></span> &nbsp;  &nbsp;   <a href="mailto:hello@foundstages.org" class="mail-link">hello@foundstages.org</a> 
            </h4>
            <div class="icons">
                <a href="https://www.facebook.com/foundstages" target="_blank" rel="noopener noreferrer">
                <span class="icon-wrapper">
                    {/* <i class="fab fa-facebook fa-3x"></i> */}
                    <FaTwitter class="github-icon" />
                </span>
                </a>
                <a href="https://www.twitter.com/foundstages" target="_blank" rel="noopener noreferrer">
                <span class="icon-wrapper">
                    {/* <i class="fab fa-twitter fa-3x"></i> */}
                    <FaFacebook class="github-icon" />
                </span>
                </a>
                <a href="https://www.instagram.com/foundstages" target="_blank" rel="noopener noreferrer">
                <span class="icon-wrapper">
                    {/* <i class="fab fa-instagram fa-3x"></i> */}
                    <FaInstagram class="github-icon" />
                </span>
                </a>
            </div>
        </div>
    )
}