import { Link } from "gatsby"
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { AppBar, Typography, IconButton, ListItem, ListItemText,
Collapse, Drawer, List } from '@material-ui/core/';

import logo from '../images/foundstages-logo-trans-bkgd.png'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: "fixed",
    zIndex: 20,
    width: "100%",
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        display: "none"
      },
  },
  menuIcon: {
    fontSize: "2rem",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "rgb(0,0,0,0.5)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    padding: 4,
    transition: "padding 0.45s",
    "-webkit-transition": "padding 0.45s",

    [theme.breakpoints.up('md')]: {
        padding: 20
      }
  },
  navBarLogo: {
      height: 64,
      width: 64,
      margin: 0
  },
  navBarLinks: {
      // flexGrow: 1,
      fontSize: "1em",
      padding: "0 10px",
      display: "flex",
      flexDirection: "column"

  },
  linksContainer: {
      display: "none",

      [theme.breakpoints.up('md')]: {
        display: "flex"
      },
  },
  link: {
    position: "relative",
    color: "#fff",
    textDecoration: "none",

    '&:before': {
    content: '""',
    position: "absolute",
    width: "100%",
    height: 1,
    bottom: 0,
    left: 0,
    backgroundColor: "#FFD700",
    visibility: "hidden",
    "-webkitTransform": "scaleX(0)",
    transform: "scaleX(0)",
    "-webkitTransition": "all 0.2s ease-in-out 0s",
    transition: "all 0.2s ease-in-out 0s"
    },

    '&:hover': {
      '&:before': {
          visibility: "visible",
          "-webkit-transform": "scaleX(1)",
          transform: "scaleX(1)"
      },
    }
  },
  dropMenu: {
      height: "calc(100% - 76px)",
      backgroundColor: "rgb(0,0,0,0.75)"
  },
  listItem: {
    borderBottom: "1px solid white",
    justifyContent: "center"
  },
  listItemNestContainer: {
    borderBottom: "1px solid white",
    display: "flex",
    flexDirection: "column"
  },
  listItemText: {
      color: "white",
      textAlign: "center",
      '&:hover': {
        color: '#FFD700'
      }
  },
  inline: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    alignItems: "center"
  },
  inlineMobile: {
    display: "flex",
    flexDirection: "row",
    cursor: "pointer",
    padding: 8,
    alignItems: "center"
  },
  liveSubmenu: {
    display: 'none',
    '&:hover': {
      display: 'block'
    }
  },
  arrow: {
    color: "white",
    alignSelf: "center"
  },
  noMargin: {
    margin: 0
  },
  subItem: {
    display: "flex",
    // justifyContent: "center",
    width: "auto",

  },
  column: {
    display: "none",
    position: "relative",

    [theme.breakpoints.up('md')]: {
      display: "flex",
      flexDirection: "column"
    }
  },
  // row: {
  //   position: "absolute",
  //   top: 30,
  // },
  center: {
    justifyContent: "center"
  },
  downArrow: {
    height: 20,
    marginBottom: 0
  },
  materialIcons: {
    fontFamily: "Material Icons"
  }
}));


export default function MenuAppBar() {
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener("scroll", resizeHeaderOnScroll);
  }, []);

  const [state, setState] = React.useState({
    bottom: false
  });

  const [mobileOpen, setOpenMobile] = React.useState(false);

  function handleClickMobile() {
    setOpenMobile(!mobileOpen);
  }

  const [mobileSupportOpen, setOpenSupportMobile] = React.useState(false);

  function handleClickSupportMobile() {
    setOpenSupportMobile(!mobileSupportOpen);
  }

  const [mainOpen, setOpenMain] = React.useState(false);

  function handleClickMain() {
    setOpenMain(!mainOpen);
  }

  const [supportOpen, setOpenSupport] = React.useState(false);

  function handleClickSupport() {
    setOpenSupport(!supportOpen);
  }

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 400,
      headerEl = document.getElementsByClassName("MuiAppBar-root");

    if (distanceY > shrinkOn) {
      headerEl[0].classList.add("smaller");
    } else {
      headerEl[0].classList.remove("smaller");
    }
  }

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const fullList = side => (

      <List>
          <ListItem className={classes.listItemNestContainer} button >
            <div className={classes.inlineMobile} onClick={handleClickMobile}>
            <ListItemText className={classes.listItemText} primary='Live Events' />
            {mobileOpen ? 
            <span className="material-icons white-text">expand_less</span> : <span className="material-icons white-text">expand_more</span>}
            {/* <ExpandLess className={classes.arrow} /> : <ExpandMore className={classes.arrow} />} */}
            </div>
            <Collapse in={mobileOpen} timeout="auto" unmountOnExit>
              
              <List component="div" disablePadding>
                <ListItem button className={classes.center} onClick={toggleDrawer('bottom', false)} >
                  <Link to="/frankensteinsfuneral">
                    <ListItemText className={classes.listItemText} primary="Frankenstein's Funeral" />
                  </Link>
                </ListItem>
                <ListItem button className={classes.center} onClick={toggleDrawer('bottom', false)} >
                  <Link to="/frankensteinsball">
                    <ListItemText className={classes.listItemText} primary="Frankenstein's Ball" />
                  </Link>
                </ListItem>
                <ListItem button className={classes.center} onClick={toggleDrawer('bottom', false)} >
                  <Link to="/wineandreadingseries" >
                    <ListItemText className={classes.listItemText} primary="Wine & Reading Series" />
                  </Link>
                </ListItem>
                <ListItem button className={classes.center} onClick={toggleDrawer('bottom', false)} >
                  <Link to="/cassies-ballad" >
                    <ListItemText className={classes.listItemText} primary="Cassie's Ballad" />
                  </Link>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
          <ListItem className={classes.listItem} button onClick={toggleDrawer('bottom', false)} >
            <Link to="/digitalplays">
              <ListItemText className={classes.listItemText} primary='Digital Plays' />
            </Link>
          </ListItem>
          <ListItem className={classes.listItem} button onClick={toggleDrawer('bottom', false)} >
            <Link to="/pastwork">
              <ListItemText className={classes.listItemText} primary='Past Work' />
            </Link>
          </ListItem>
          <ListItem className={classes.listItem} button onClick={toggleDrawer('bottom', false)} >
            <Link to="/about">
              <ListItemText className={classes.listItemText} primary='About' />
            </Link>
          </ListItem>
          <ListItem className={classes.listItem} button onClick={toggleDrawer('bottom', false)} >
            <Link to="/contact">
              <ListItemText className={classes.listItemText} primary='Contact' />
            </Link>
          </ListItem>
          <ListItem className={classes.listItemNestContainer} button>
            <div className={classes.inlineMobile} onClick={handleClickSupportMobile}>
              <ListItemText className={classes.listItemText} primary='Support Us' />

            {mobileSupportOpen ? 
            <span className="material-icons white-text">expand_less</span> : <span className="material-icons white-text">expand_more</span>}
            {/* <ExpandLess className={classes.arrow} /> : <ExpandMore className={classes.arrow} />} */}
            </div>
            <Collapse in={mobileSupportOpen} timeout="auto" unmountOnExit>
              
              <List component="div" disablePadding>
                <ListItem button className={classes.center} onClick={toggleDrawer('bottom', false)} >
                  <a href="https://secure.givelively.org/donate/found-stages-inc">
                    <ListItemText className={classes.listItemText} primary='Donate' />
                  </a>
                </ListItem>
                <ListItem button className={classes.center} onClick={toggleDrawer('bottom', false)} >
                  <Link to="/found-swag">
                    <ListItemText className={classes.listItemText} primary="Buy Found Swag" />
                  </Link>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
      </List>
  );
  

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Link to="/">
          <img className={classes.navBarLogo} src={logo} alt="" />
        </Link>
        <IconButton edge="start" className={classes.menuButton} color="inherit" onClick={toggleDrawer('bottom', true)}>
          <span className="material-icons">menu</span>          
        </IconButton>
        <Drawer 
            anchor="bottom" 
            classes={{ 
                paperAnchorBottom: classes.dropMenu 
            }}
            open={state.bottom} 
            onClose={toggleDrawer('bottom', false)}>
            {fullList('bottom')}
        </Drawer>
        <div className={classes.column}>
          <List className={classes.linksContainer}>
              <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickMain} >
                  {/* <Link to="/live-events" className={classes.link}> */}
                      <div className={classes.inline}>
                        <p className={classes.noMargin}>Live Events</p>
                        {mainOpen ? 
                        <span className="material-icons white-text">expand_less</span>  : <span className="material-icons">expand_more</span>}
                      </div>
                  {/* </Link> */}
              </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
              <Typography variant="h6" className={classes.navBarLinks}>
                  <Link to="/digitalplays" className={classes.link}>
                      Digital Plays
                  </Link>
              </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
              <Typography variant="h6" className={classes.navBarLinks}>
                  <Link to="/pastwork" className={classes.link}>
                      Past Work
                  </Link>
              </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
              <Typography variant="h6" className={classes.navBarLinks}>
                  <Link to="/about" className={classes.link}>
                      About
                  </Link>
              </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
              <Typography variant="h6" className={classes.navBarLinks}>
                  <Link to="/contact" className={classes.link}>
                      Contact
                  </Link>
              </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
              <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickSupport} >
                  

                  <div className={classes.inline}>
                        <p className={classes.noMargin}>Support Us</p>
                        {supportOpen ? 
                        <span className="material-icons white-text">expand_less</span>  : <span className="material-icons">expand_more</span>}
                      </div>
                  {/* </Link> */}
              </Typography>
            </List>

            {/* Expandable Menu for Live Events */}
            <Collapse in={mainOpen} timeout="auto" unmountOnExit className={classes.row}>
                
                <List component="div" className={classes.subItem}>
                  <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickMain}>
                    <Link to="/frankensteinsfuneral" className={classes.link}>
                        Frankenstein's Funeral
                    </Link>
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickMain}>
                    <Link to="/frankensteinsball" className={classes.link}>
                        Frankenstein's Ball
                    </Link>
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickMain}>
                      <Link to="/wineandreadingseries" className={classes.link}>
                          Wine & Reading Series
                      </Link>
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickMain}>
                      <Link to="/cassies-ballad" className={classes.link}>
                          Cassie's Ballad
                      </Link>
                  </Typography>
                </List>
              </Collapse>

              {/* Expandable Menu for Support Us */}
              <Collapse in={supportOpen} timeout="auto" unmountOnExit className={classes.row}>
                
                <List component="div" className={classes.subItem}>
                  <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickSupport}>
                    <a href="https://secure.givelively.org/donate/found-stages-inc" className={classes.link}>
                        Donate
                    </a>
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks}>
                      /
                  </Typography>
                  <Typography variant="h6" className={classes.navBarLinks} onClick={handleClickSupport}>
                      <Link to="/found-swag" className={classes.link}>
                          Buy Found Swag
                      </Link>
                  </Typography>
                </List>
              </Collapse>
          </div>
      </AppBar>
    </div>
  );
}